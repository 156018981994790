<template>
  <md-card
    :class="{
      'md-card-plain': cardPlain,
      'md-card-blogpost': type === 'horizontal'
    }"
    class="md-card-blog"
  >
    <template v-if="type !== 'horizontal'">
      <md-card-header
        :shadow-normal="shadowNormal"
        :no-colored-shadow="noColoredShadow"
        class="md-card-header-image"
      >
        <nuxt-link v-if="link" :to="link">
          <img :src="cardImage" class="img" />
          <div v-if="$slots.cardTitleHeader" class="card-title">
            <slot name="cardTitleHeader" />
          </div>
        </nuxt-link>
        <div v-else>
          <img :src="cardImage" class="img" />
          <div v-if="$slots.cardTitleHeader" class="card-title">
            <slot name="cardTitleHeader" />
          </div>
        </div>
        <div
          v-if="!noColoredShadow && !shadowNormal"
          :style="shadowImageBlog(cardImage)"
          class="colored-shadow"
        />
      </md-card-header>

      <md-card-content v-if="$slots.cardContent">
        <slot name="cardContent" />
      </md-card-content>

      <md-card-actions v-if="$slots.cardAction">
        <slot name="cardAction" />
      </md-card-actions>
    </template>

    <template v-else>
      <div :class="{ 'reverse-row': reverseRow }" class="md-layout">
        <div class="md-layout-item md-size-45 md-xsmall-size-100">
          <md-card-header
            :shadow-normal="shadowNormal"
            :no-colored-shadow="noColoredShadow"
            class="md-card-header-image"
          >
            <a href="javascript:void(0)">
              <img :src="cardImage" class="img" />
              <div v-if="$slots.cardTitleHeader" class="card-title">
                <slot name="cardTitleHeader" />
              </div>
            </a>
            <div
              v-if="!noColoredShadow && !shadowNormal"
              :style="shadowImageBlog(cardImage)"
              class="colored-shadow"
            />
          </md-card-header>
        </div>
        <div class="md-layout-item md-size-55 md-xsmall-size-100">
          <md-card-content v-if="$slots.cardContent">
            <slot name="cardContent" />
          </md-card-content>
        </div>
      </div>
    </template>
  </md-card>
</template>

<script>
export default {
  name: 'BlogCard',
  props: {
    type: {
      type: String,
      default: ''
    },
    reverseRow: {
      type: Boolean,
      default: false
    },
    cardImage: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: null
    },
    shadowNormal: {
      type: Boolean,
      default: false
    },
    cardPlain: {
      type: Boolean,
      default: false
    },
    noColoredShadow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    shadowImageBlog(image) {
      return {
        backgroundImage: `url(${image})`,
        opacity: 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reverse-row {
  flex-direction: row-reverse;
}
</style>
