import { render, staticRenderFns } from "./MainFooter.vue?vue&type=template&id=6cb9b803&scoped=true&"
import script from "./MainFooter.vue?vue&type=script&lang=js&"
export * from "./MainFooter.vue?vue&type=script&lang=js&"
import style0 from "./MainFooter.vue?vue&type=style&index=0&id=6cb9b803&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb9b803",
  null
  
)

export default component.exports