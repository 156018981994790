<template>
  <md-card
    :class="{
      [cardColor]: true,
      'md-card-plain': cardPlain
    }"
    :style="bgFullCards(cardImage)"
    class="md-card-pricing"
  >
    <md-card-content v-if="$slots.cardContent">
      <slot name="cardCategory" />
      <slot name="cardContent" />
    </md-card-content>
    <md-card-actions v-if="$slots.cardAction">
      <slot name="cardAction" />
    </md-card-actions>
  </md-card>
</template>

<script>
export default {
  name: 'PricingCard',
  props: {
    cardPlain: {
      type: Boolean,
      default: false
    },
    cardBg: {
      type: String,
      default: ''
    },
    cardImage: {
      type: String,
      default: ''
    }
  },
  computed: {
    cardColor() {
      return this.cardBg ? `bg-${this.cardBg}` : ''
    }
  },
  methods: {
    bgFullCards(cardImage) {
      if (this.cardImage) {
        return {
          backgroundImage: `url(${cardImage})`
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
