<template>
  <div
    id="vue-material-kit-pro"
    :class="{ 'nav-open': NavbarStore.showNavbar }"
  >
    <main-navbar />

    <div class="wrapper">
      <FadeTransition :duration="300">
        <nuxt />
      </FadeTransition>
    </div>

    <login
      :show="getSignupModal"
      :login-context="loginContext"
      @hide-login-modal="hideSignupModal"
      @changeLoginContext="changeLoginContext"
    />

    <main-footer />
  </div>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import { FadeTransition } from 'vue2-transitions'
import Login from '@/components/public/Login.vue'
import MainFooter from '@/components/public/layout/MainFooter'
import MainNavbar from '@/components/public/layout/MainNavbar'
// import KitProPlugin from '~/plugins/kit-pro.js'

export default {
  components: {
    FadeTransition,
    Login,
    MainFooter,
    MainNavbar
  },
  data() {
    return {
      loginContext: -1
    }
  },
  computed: {
    ...Vuex.mapGetters(['getCurrentLayout']),
    ...Vuex.mapGetters(['getSignupModal'])
  },
  watch: {
    $route() {
      //      if (this.getCurrentLayout && this.$route.name.substr(0, 6) === 'admin-') {
      // If there is a layout change
      //        window.location.reload(true)
      //      }
    }
  },
  beforeCreate() {
    //    if (process.browser) {
    //      // eslint-disable-next-line
    //      document.getElementsByTagName('html')[0].removeAttribute('style')
    //    }
    //    Vue.use(KitProPlugin)
  },
  created() {},
  mounted() {
    // eslint-disable-next-line
    console.log('mounted manager-static')
    // Add required theme class
    //    const docClasses = document.body.classList
    //    docClasses.add('kit-pro')
    this.initLocale()
    this.$root.$on('registerclicked', () => {
      // Go to register form
      this.isLoginModalActive = true
      this.loginContext = 1
    })
    this.$root.$on('loginclicked', () => {
      // Go to login form
      //      console.log("login clicked")
      this.isLoginModalActive = true
      this.loginContext = 2
    })
    this.$root.$on('changepwdclicked', () => {
      // Go to lost password form
      this.isLoginModalActive = true
      this.loginContext = 3
    })
  },
  methods: {
    ...Vuex.mapMutations(['setSignupModal']),
    initLocale() {
      this.$moment.locale(this.$i18n.locale)
      this.$axios.defaults.headers.common['Accept-Language'] = this.$i18n.locale
      Vue.material.locale.dateFormat = this.$t('common.date_format')
    },
    hideSignupModal() {
      this.setSignupModal(false)
    },
    changeLoginContext(val) {
      this.loginContext = val
    }
  },
  head() {
    const appName = 'Manager goyoga'
    const appVersion = require('@/package.json').version
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      title: appName + ' - ' + appVersion,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: appName + ' - ' + appVersion
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('common.description_goyoga_home')
        }
      ],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/icon?family=Material+Icons'
        },
        {
          rel: 'stylesheet',
          href: 'https://use.fontawesome.com/releases/v5.0.8/css/all.css'
        },
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: '/img/favicon.ico'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import 'vue-material/dist/vue-material.min.css';
@import '@/assets/scss/material-kit.scss';
@import '@/assets/css/mkDemo.css';
@import 'vue-github-buttons/dist/vue-github-buttons.css';
.container {
  justify-content: center;
}
</style>
