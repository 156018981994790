<template>
  <form @submit.prevent="validateBeforeSubmit">
    <validated-input
      :key="resetPwdInputs.password.nameAttr"
      :field="resetPwdInputs.password"
      @form-errors="setFormErrors"
    />
    <validated-input
      :key="resetPwdInputs.confirmPassword.nameAttr"
      :field="resetPwdInputs.confirmPassword"
      @form-errors="setFormErrors"
    />
    <div class="button-container justify-content-center">
      <md-button
        :disabled="
          !(formErrors && formErrors.items && formErrors.items.length === 0) ||
            resetPwdInputs.confirmPassword.value !==
              resetPwdInputs.password.value
        "
        class="md-primary md-round mt-3"
        type="submit"
      >
        {{ $t('lostpwd.buttons.reset_submit_btn') }}
      </md-button>
    </div>
  </form>
</template>

<script>
import ValidatedInput from '@/components/common/ValidatedInput'

export default {
  components: {
    ValidatedInput
  },
  props: {},

  data() {
    return {
      formErrors: null,
      resetPwdInputs: {
        password: {
          icon: 'lock_outline',
          name: this.$t('account.password'),
          nameAttr: this.$t('account.password'),
          type: 'password',
          value: null,
          validate: 'required|min:6',
          touched: false
        },
        confirmPassword: {
          icon: 'lock_outline',
          name: this.$t('account.confirm'),
          nameAttr: this.$t('account.confirm'),
          type: 'password',
          value: null,
          validate: 'required|min:6',
          touched: false
        }
      }
    }
  },
  computed: {},

  watch: {},

  created() {},

  mounted() {
    this.$nextTick(() => {})
  },

  methods: {
    validateBeforeSubmit(e) {
      if (this.formErrors.items.length === 0) {
        e.preventDefault()
        this.$validator
          .validateAll()
          .then(() => {
            // Data is valid, so we can submit the form.
            const model = {
              password: this.resetPwdInputs.password.value,
              token: this.$route.query.t
            }
            this.$emit('validate-reset-pwd-form', model)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error)
            return false
          })
      }
    },
    setFormErrors(errors) {
      this.formErrors = errors
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-wrapper {
  display: block;
}
</style>
