<template>
  <footer class="footer">
    <template>
      <div class="container flex-direction-row">
        <span class="footer-brand">GOYOGA Manager v2</span>
        <ul>
          <li>
            <a href="https://www.goyoga.fr" target="_blank">
              {{ $t('home.goyoga_public') }}
            </a>
          </li>
          <li>
            <a href="https://www.goyoga.fr/blog" target="_blank">
              Blog
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push(localePath('/cgu'))"
            >
              CGU
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push(localePath('/mentions-legales'))"
            >
              Mentions légales
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push(localePath('/tutorials'))"
            >
              {{ $t('menu.help') }}
            </a>
          </li>
          <li>
            <a href="https://www.l-wa.fr" target="_blank">
              {{ $t('menu.created_by') }}
            </a>
          </li>
          <!--          <li>
            <a href="javascript:void(0)">
              Licenses
            </a>
          </li>-->
        </ul>

        <ul class="social-buttons">
          <li>
            <md-button
              href="https://www.facebook.com/goyogaNews"
              target="_blank"
              rel="nofollow"
              class="md-facebook md-just-icon md-simple"
            >
              <i class="fab fa-facebook" />
            </md-button>
          </li>
          <li>
            <md-button
              href="https://www.instagram.com/goyoganews/"
              target="_blank"
              rel="nofollow"
              class="md-instagram md-just-icon md-simple"
            >
              <i class="fab fa-instagram" />
            </md-button>
          </li>
          <li>
            <md-button
              href="https://www.youtube.com/channel/UCxoCBspaH4O7sVHXtppkPHQ"
              target="_blank"
              rel="nofollow"
              class="md-youtube md-just-icon md-simple"
            >
              <i class="fab fa-youtube" />
            </md-button>
          </li>
          <!--          <li>
            <md-button
              href="https://www.linkedin.com/in/christophe-bourgois/"
              target="_blank"
              rel="nofollow"
              class="md-linkedin md-just-icon md-simple"
            >
              <i class="fab fa-linkedin" />
            </md-button>
          </li>-->
        </ul>
      </div>
    </template>
  </footer>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.container.flex-direction-row {
  justify-content: space-between;
}
</style>
