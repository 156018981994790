<template>
  <form @submit.prevent="validateBeforeSubmit">
    <template v-if="affiliates">
      <p class="text-success">
        {{
          $t('register.affiliator', {
            firstName: affiliates.affiliator.firstName,
            lastName: affiliates.affiliator.lastName,
            phone: affiliates.affiliator.phone
          })
        }}
      </p>
      <validated-input
        v-for="item in registerInputs"
        :key="item.nameAttr"
        :field="item"
        @form-errors="setFormErrors"
      />
      <!--                  <md-checkbox v-model="boolean">
        I agree to the
        <a>terms and conditions</a>.
      </md-checkbox>-->
      <div class="button-container justify-content-center">
        <md-button
          :disabled="
            !(formErrors && formErrors.items && formErrors.items.length === 0)
          "
          class="md-primary md-round mt-3"
          type="submit"
        >
          {{ $t('register.buttons.submitbtn') }}
        </md-button>
      </div>
    </template>
    <template v-else>
      <p class="text-danger">
        {{ $t('register.no_affiliator') }}
      </p>
      <h4 class="info-title">
        {{ $t('register.why_affiliator_title') }}
      </h4>
      <p class="description">
        {{ $t('register.why_affiliator_text') }}
      </p>
      <h4 class="info-title">
        {{ $t('register.how_affiliator_title') }}
      </h4>
      <p class="description">
        {{ $t('register.how_affiliator_text1') }}
        <a href="javascript:void(0)" @click="goContactPage"
          >{{ $t('register.how_affiliator_text2') }}
        </a>
        {{ $t('register.how_affiliator_text3') }}
      </p>
    </template>
  </form>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import ValidatedInput from '@/components/common/ValidatedInput'

export default {
  components: {
    ValidatedInput
  },
  props: {},

  data() {
    return {
      formErrors: null,
      affiliates: false,
      registerInputs: {
        username: {
          icon: 'face',
          name: this.$t('account.username'),
          nameAttr: this.$t('account.username'),
          type: 'text',
          value: '',
          validate: 'required|min:3',
          touched: false
        },
        email: {
          icon: 'email',
          name: this.$t('account.email'),
          nameAttr: this.$t('account.email'),
          type: 'email',
          value: '',
          validate: 'required|email',
          touched: false
        },
        password: {
          icon: 'lock_outline',
          name: this.$t('account.password'),
          nameAttr: this.$t('account.password'),
          type: 'password',
          value: '',
          validate: 'required|min:6',
          touched: false
        }
      }
    }
  },
  computed: {},

  watch: {},

  created() {},

  mounted() {
    this.$nextTick(() => {
      if (this.$route.query && this.$route.query.t) {
        this.initRegister()
      }
    })
  },

  methods: {
    ...mapMutations(['setSignupModal']),
    ...mapActions(['fetchAffiliates']),
    goContactPage() {
      this.setSignupModal(false)
      this.$router.push(this.localePath('/contact'))
    },
    validateBeforeSubmit(e) {
      if (this.formErrors.items.length === 0) {
        e.preventDefault()
        this.$validator
          .validateAll()
          .then(() => {
            // Data is valid, so we can submit the form.
            const model = {
              plainPassword: {
                first: this.registerInputs.password.value,
                second: this.registerInputs.password.value
              },
              username: this.registerInputs.username.value,
              email: this.registerInputs.email.value,
              affiliate: this.$route.query.t
            }
            this.$emit('validate-register-form', model)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error)
            return false
          })
      }
    },
    setFormErrors(errors) {
      this.formErrors = errors
    },
    initRegister() {
      try {
        this.fetchAffiliates(this.$route.query.t)
          .then((res) => {
            this.affiliates = res
            this.registerInputs.username.value =
              this.affiliates &&
              this.affiliates.firstName &&
              this.affiliates.lastName
                ? this.affiliates.firstName + ' ' + this.affiliates.lastName
                : ''
            this.registerInputs.email.value =
              this.affiliates && this.affiliates.email
                ? this.affiliates.email
                : ''
          })
          .catch((e) => {
            this.affiliates = false
            this.tools.notifyError(e, this)
          })
      } catch (e) {
        this.affiliates = false
        this.tools.notifyError(e, this)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-wrapper {
  display: block;
}
</style>
