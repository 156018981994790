<template>
  <div
    :class="{
      'info-horizontal': infoHorizontal,
      'text-right': textRight,
      'text-left': textLeft,
      'text-center': textCenter
    }"
    class="info"
  >
    <div :class="infoColor" class="icon">
      <md-icon>{{ icon }}</md-icon>
    </div>
    <template v-if="!infoHorizontal">
      <slot name="title" />
      <slot name="content" />
    </template>
    <template v-else>
      <div class="description">
        <slot name="title" />
        <slot name="content" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'InfoAreas',
  props: {
    textCenter: {
      type: Boolean,
      default: false
    },
    textRight: {
      type: Boolean,
      default: false
    },
    textLeft: {
      type: Boolean,
      default: false
    },
    infoHorizontal: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    infoColor() {
      return this.iconColor ? `icon-${this.iconColor}` : ''
    }
  }
}
</script>

<style lang="scss"></style>
