<template>
  <form @submit.prevent="validateBeforeSubmit">
    <validated-input
      v-for="item in lostPwdInputs"
      :key="item.nameAttr"
      :field="item"
      @form-errors="setFormErrors"
    />
    <div class="button-container justify-content-center">
      <md-button
        :disabled="
          !(formErrors && formErrors.items && formErrors.items.length === 0)
        "
        class="md-primary md-round mt-3"
        type="submit"
      >
        {{ $t('lostpwd.buttons.submitbtn') }}
      </md-button>
    </div>
  </form>
</template>

<script>
import ValidatedInput from '@/components/common/ValidatedInput'

export default {
  components: {
    ValidatedInput
  },
  props: {},

  data() {
    return {
      formErrors: null,
      lostPwdInputs: {
        email: {
          icon: 'email',
          name: this.$t('account.email'),
          nameAttr: this.$t('account.email'),
          type: 'email',
          value: null,
          validate: 'required|email',
          touched: false
        }
      }
    }
  },
  computed: {},

  watch: {},

  created() {},

  mounted() {
    this.$nextTick(() => {})
  },

  methods: {
    validateBeforeSubmit(e) {
      if (this.formErrors.items.length === 0) {
        e.preventDefault()
        this.$validator
          .validateAll()
          .then(() => {
            // Data is valid, so we can submit the form.
            const model = {
              email: this.lostPwdInputs.email.value
            }
            this.$emit('validate-lost-pwd-form', model)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error)
            return false
          })
      }
    },
    setFormErrors(errors) {
      this.formErrors = errors
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-wrapper {
  display: block;
}
</style>
