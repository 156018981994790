export const tools = {
  getUserComingSoonMessage(user, vm) {
    const phone = user && user.phone ? user.phone : null
    const email = user && user.email ? user.email : null
    const firstName = user && user.firstName ? user.firstName : null
    const lastName = user && user.lastName ? user.lastName : null
    let message = vm.$t('reservation.labels.no_payment_process')
    if (phone || email) {
      message =
        message +
        '<BR/>' +
        vm.$t('reservation.labels.no_payment_process_contact_teacher')
      if (firstName || lastName) {
        message = message + '('
        message = firstName ? message + firstName + ' ' : message + ' '
        message = lastName ? message + lastName : message
        message = message + ') '
      }
      message = phone ? message + phone : message
      message = phone && email ? message + '/' : message
      message = email
        ? message + "<a href='mailto:" + email + "'>" + email + '</a>'
        : message
      return message
    }
  },

  getSaasBaseUrl(theme) {
    switch (theme) {
      case 'goyoga':
        return process.env.SASS_GY_URL
      case 'gopilates':
        return process.env.SASS_GP_URL
      case 'gotherapy':
        return process.env.SASS_GT_URL
      default:
        return process.env.SASS_GY_URL
    }
  },

  slugify(string) {
    const a =
      'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
    const b =
      'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return (
      string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        // eslint-disable-next-line
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        // eslint-disable-next-line
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '')
    ) // Trim - from end of text
  },

  capitalize(str) {
    if (str && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    } else {
      return str
    }
  },

  stripHtml(html) {
    let result = html
    try {
      result = html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '')
    } catch (error) {
      // eslint-disable-next-line
      console.log('error stripHtml: ' + JSON.stringify(error))
    }
    return result
  },

  updateRouter(params, vm) {
    const queryObj = JSON.parse(JSON.stringify(vm.$route.query))
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        queryObj[key] = value
      } else {
        vm.$delete(queryObj, key)
        vm.$delete(queryObj, `${key}Name`)
      }
    }
    // Update url
    vm.$router.replace({ query: queryObj })
  },

  wip(vm) {
    vm.$notify({
      message:
        vm.$t('common.work_in_progress_1') +
        ' ' +
        vm.$t('common.work_in_progress_2'),
      icon: 'warning',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'warning'
    })
  },

  notifySuccess(vm) {
    vm.$notify({
      message: vm.$t('common.save_success'),
      icon: 'check_circle',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'success'
    })
  },

  notifyMessage(message, vm) {
    vm.$notify({
      message,
      icon: 'info',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'warning'
    })
  },

  notifyError(error, vm) {
    try {
      let errorMsg = error
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMsg = vm.$t('error.' + error.response.data.message)
      } else if (error && error.message) {
        errorMsg = vm.$t(error.message)
      }
      vm.$notify({
        message: vm.$t('common.save_error') + ' ' + errorMsg,
        icon: 'warning',
        horizontalAlign: 'center',
        verticalAlign: 'middle',
        type: 'danger'
      })
    } catch (e) {
      // eslint-disable-next-line
      console.log('error notifyError: ' + JSON.stringify(e))
    }
  },

  getDateTime(date, time = null, format, vm) {
    const dateTime = vm.$moment(
      date,
      vm.$moment.HTML5_FMT.DATETIME_LOCAL_SECONDS
    )
    if (time) {
      dateTime.hour(time.substr(0, 2))
      dateTime.minute(time.substr(3, 4))
    }
    return dateTime.format(format)
  },

  addressToString(address) {
    if (address) {
      let result = address.address ? address.address : ''
      result = address.zipCode ? result + ', ' + address.zipCode : result
      result =
        address.zipCode && address.city ? result + ' ' + address.city : result
      result = address.country ? result + ', ' + address.country : result
      return result
    }
    return ''
  },

  getObjectIds(arrayData) {
    const ids = []
    if (arrayData && arrayData.length > 0) {
      arrayData.forEach((data) => {
        if (data.id) {
          ids.push(data.id)
        }
      })
    }
    return ids
  },

  getPlanningModelRecurring(planning, vm) {
    const model = {}
    let hasBattlements = 1
    if (
      planning &&
      planning.serviceSchedules &&
      planning.serviceSchedules.length > 0
    ) {
      planning.serviceSchedules.forEach((schedule) => {
        if (Object.prototype.hasOwnProperty.call(schedule, 'timeTo')) {
          hasBattlements = 0
        }
      })
    }
    if (hasBattlements === 0) {
      model.withInterval = planning.withInterval
      model.intervalDuration = planning.intervalDuration
    }
    model.dateFrom = planning.dateFrom
    model.recurrence = planning.recurrence
    if (model.recurrence === 2) {
      model.repetitionPeriod = planning.repetitionPeriod
      model.dateTo = planning.dateTo
    }
    model.duration = planning.duration
    model.durationType = planning.durationType || 1
    model.dayOff = planning.dayOff
    model.holiday = planning.holiday
    if (planning.serviceSchedules) {
      model.serviceSchedules = planning.serviceSchedules
    }
    return model
  },

  getCivility(civility, vm) {
    switch (civility) {
      case 1:
        return vm.$t('account.profile.civility.mr')
      case 2:
        return vm.$t('account.profile.civility.mrs')
      case 3:
        return vm.$t('account.profile.civility.ms')
      default:
        return ''
    }
  },

  getTeacherProfilePercent(user) {
    let percent = 0
    if (user && user.teacher) {
      user.teacher.address ? (percent += 25) : (percent += 0)
      user.teacher.languages ? (percent += 25) : (percent += 0)
      user.teacher.phone ? (percent += 25) : (percent += 0)
      user.teacher.facebook ||
      user.teacher.instagram ||
      user.teacher.twitter ||
      user.teacher.linkedin
        ? (percent += 25)
        : (percent += 0)
    }
    return percent
  },

  teacherDisciplinePercent(teacherDiscipline, fieldsList) {
    let expectedTotal = 0
    let disciplineTotal = 0
    if (teacherDiscipline) {
      // Images
      expectedTotal += 10
      teacherDiscipline.images
        ? teacherDiscipline.images.length >= 3
          ? (disciplineTotal += 10)
          : (disciplineTotal += 10 * (teacherDiscipline.images.length / 3))
        : (disciplineTotal += 0)
      // Diplomas
      expectedTotal += 10
      teacherDiscipline.diplomas && teacherDiscipline.diplomas.length > 0
        ? (disciplineTotal += 10)
        : (disciplineTotal += 0)
      // Qualities
      expectedTotal += 10
      teacherDiscipline.qualities && teacherDiscipline.qualities.length > 0
        ? (disciplineTotal += 10)
        : (disciplineTotal += 0)
      // CourseTYpes
      expectedTotal += 10
      teacherDiscipline.courseTypes && teacherDiscipline.courseTypes.length > 0
        ? (disciplineTotal += 10)
        : (disciplineTotal += 0)
      // Fields
      fieldsList.forEach((field) => {
        let fieldWeight = 10
        switch (field) {
          case 'citation':
            fieldWeight = 10
            break
          case 'description':
            fieldWeight = 20
            break
          case 'inspiration':
            fieldWeight = 10
            break
          case 'reference':
            fieldWeight = 10
            break
        }
        // Field fr
        expectedTotal += fieldWeight
        disciplineTotal +=
          (fieldWeight *
            this.getTranslationFieldPercent(teacherDiscipline, field, 'fr')) /
          100
        // Field en
        expectedTotal += fieldWeight
        disciplineTotal +=
          (fieldWeight *
            this.getTranslationFieldPercent(teacherDiscipline, field, 'en')) /
          100
      })
    }
    return expectedTotal > 0
      ? parseFloat(
          (parseFloat(disciplineTotal / expectedTotal) * 100).toFixed(2)
        )
      : 0
  },

  getTranslationFieldPercent(obj, field, language) {
    const countWords = this.countWords(
      this.getTranslationExactLanguage(obj, field, language)
    )
    let expectedWords = 1
    switch (field) {
      case 'name':
        expectedWords = 1
        break
      case 'citation':
        expectedWords = 30
        break
      case 'description':
        expectedWords = 300
        break
      case 'inspiration':
        expectedWords = 50
        break
      case 'reference':
        expectedWords = 50
        break
    }
    const percent = countWords / expectedWords
    return percent > 1 ? 100 : percent * 100
  },
  countWords(str) {
    if (str) {
      return str.trim().split(/\s+/).length
    } else return 0
  },
  placePercent(place) {
    let expectedTotal = 0
    let placeTotal = 0
    if (place) {
      // Images
      expectedTotal += 10
      place.images
        ? place.images.length >= 3
          ? (placeTotal += 10)
          : (placeTotal += 10 * (place.images.length / 3))
        : (placeTotal += 0)
      // Capacity
      expectedTotal += 10
      place.capacity > 0 ? (placeTotal += 10) : (placeTotal += 0)
      // ContactName
      expectedTotal += 10
      place.contactName && place.contactName.length > 0
        ? (placeTotal += 10)
        : (placeTotal += 0)
      // ContactPhone
      expectedTotal += 10
      place.contactPhone && place.contactPhone.length > 0
        ? (placeTotal += 10)
        : (placeTotal += 0)
      // PlaceType
      expectedTotal += 10
      place.placeType && place.placeType.id
        ? (placeTotal += 10)
        : (placeTotal += 0)
      // Address
      expectedTotal += 10
      place.address && place.address.id ? (placeTotal += 10) : (placeTotal += 0)
      // EnergySources
      expectedTotal += 10
      place.energySources && place.energySources.length > 0
        ? (placeTotal += 10)
        : (placeTotal += 0)
      // PlaceAccesses
      expectedTotal += 10
      place.placeAccesses && place.placeAccesses.length > 0
        ? (placeTotal += 10)
        : (placeTotal += 0)
      // PlaceOptions
      expectedTotal += 10
      place.placeOptions && place.placeOptions.length > 0
        ? (placeTotal += 10)
        : (placeTotal += 0)
      // Fields
      const fieldsList = ['name', 'description']
      fieldsList.forEach((field) => {
        let fieldWeight = 10
        switch (fieldsList) {
          case 'name':
            fieldWeight = 10
            break
          case 'description':
            fieldWeight = 20
            break
        }
        // Field fr
        expectedTotal += fieldWeight
        placeTotal +=
          (fieldWeight * this.getTranslationFieldPercent(place, field, 'fr')) /
          100
        // Field en
        expectedTotal += fieldWeight
        placeTotal +=
          (fieldWeight * this.getTranslationFieldPercent(place, field, 'en')) /
          100
      })
    }
    return expectedTotal > 0
      ? parseFloat((parseFloat(placeTotal / expectedTotal) * 100).toFixed(2))
      : 0
  },
  servicePercent(service) {
    let expectedTotal = 0
    let serviceTotal = 0
    if (service) {
      // Check is published
      //      if (!(service.statusDetails && service.statusDetails.published)) {
      //        return 0
      //      }
      // Images
      expectedTotal += 10
      service.images
        ? service.images.length >= 5
          ? (serviceTotal += 10)
          : (serviceTotal += 10 * (service.images.length / 5))
        : (serviceTotal += 0)
      // Minimum 1 coming session
      expectedTotal += 10
      service.countComingSessions > 0
        ? (serviceTotal += 10)
        : (serviceTotal += 0)
      // Languages
      expectedTotal += 10
      service.languages && service.languages.length > 0
        ? (serviceTotal += 10)
        : (serviceTotal += 0)
      // Disciplines
      expectedTotal += 10
      service.disciplines && service.disciplines.length > 0
        ? (serviceTotal += 10)
        : (serviceTotal += 0)
      // Plannings
      expectedTotal += 10
      service.plannings && service.plannings.length > 0
        ? (serviceTotal += 10)
        : (serviceTotal += 0)
      // Fields
      const fieldsList = ['name', 'description']
      fieldsList.forEach((field) => {
        let fieldWeight = 10
        switch (field) {
          case 'name':
            fieldWeight = 10
            break
          case 'description':
            fieldWeight = 50
            break
        }
        // Field fr
        expectedTotal += fieldWeight
        serviceTotal +=
          (fieldWeight *
            this.getTranslationFieldPercent(service, field, 'fr')) /
          100
        // Field en
        expectedTotal += fieldWeight
        serviceTotal +=
          (fieldWeight *
            this.getTranslationFieldPercent(service, field, 'en')) /
          100
      })
    }
    return expectedTotal > 0
      ? parseFloat((parseFloat(serviceTotal / expectedTotal) * 100).toFixed(2))
      : 0
  },
  getPaymentMethod(method, vm) {
    switch (method) {
      case 1:
        return vm.$t('common.payment_method_cb')
      case 2:
        return vm.$t('common.payment_method_sepa')
      case 3:
        return vm.$t('common.payment_method_cheque')
      case 4:
        return vm.$t('common.payment_method_cash')
      case 5:
        return vm.$t('common.payment_method_tpe')
    }
  },
  formatCurrency(value, vm = null) {
    if (typeof value !== 'number') {
      return value
    }
    const locale = vm ? vm.$i18n.locale : 'fr-FR'
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    })
    return formatter.format(value)
  }
}
