<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <a href="javascript:void(0)" @click="$router.push(localePath('/'))">
          <h3 class="md-title">
            {{ brand }}
          </h3>
        </a>
      </div>

      <div class="md-toolbar-section-center">
        <md-list class="ml-auto">
          <md-list-item
            href="javascript:void(0)"
            @click="$router.push(localePath('/features'))"
          >
            {{ $t('menu.features') }}
          </md-list-item>
          <md-list-item
            href="javascript:void(0)"
            @click="$router.push(localePath('/prices'))"
          >
            {{ $t('menu.price') }}
          </md-list-item>
          <md-list-item
            href="javascript:void(0)"
            @click="$router.push(localePath('/contact'))"
          >
            {{ $t('menu.contact') }}
          </md-list-item>
          <md-list-item
            href="javascript:void(0)"
            @click="$router.push(localePath('/tutorials'))"
          >
            {{ $t('menu.help') }}
          </md-list-item>

          <li class="md-list-item">
            <div class="md-list-item-content">
              <md-button
                v-if="$auth && $auth.loggedIn"
                class="md-button md-primary md-theme-default md-sm"
                href="/admin/dashboard/activity"
              >
                <md-icon>account_circle</md-icon>
                {{ $t('home.go_admin') }}
              </md-button>
              <md-button
                v-else
                class="md-button md-primary md-theme-default md-sm"
                @click="$router.push(localePath('signup'))"
              >
                <md-icon>login</md-icon> {{ $t('home.login') }}
              </md-button>
            </div>
          </li>
        </md-list>
      </div>

      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>

            <md-list>
              <md-list-item
                href="https://www.facebook.com/goyogaNews"
                target="_blank"
                rel="nofollow"
              >
                <i class="fab fa-facebook-square" />
              </md-list-item>
              <md-list-item
                href="https://www.instagram.com/goyoganews/"
                target="_blank"
                rel="nofollow"
              >
                <i class="fab fa-instagram" />
              </md-list-item>
              <md-list-item
                href="https://www.youtube.com/channel/UCxoCBspaH4O7sVHXtppkPHQ"
                target="_blank"
                rel="nofollow"
              >
                <i class="fab fa-youtube" />
              </md-list-item>
              <!--              <md-list-item
                href="https://twitter.com/goyoganews"
                target="_blank"
              >
                <i class="fab fa-twitter" />
              </md-list-item>-->
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
  <!--  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">
          {{ brand }}
        </h3>
      </div>

      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
               Here you can add your items from the section-start of your toolbar 
            </mobile-menu>

            <md-list>
              <md-list-item
                href="javascript:void(0)"
                @click="$router.push(localePath('/'))"
              >
                {{ $t('menu.home') }}
              </md-list-item>
              <md-list-item
                href="javascript:void(0)"
                @click="$router.push(localePath('/features'))"
              >
                {{ $t('menu.features') }}
              </md-list-item>
              <md-list-item
                href="javascript:void(0)"
                @click="$router.push(localePath('/about_us'))"
              >
                {{ $t('menu.about_us') }}
              </md-list-item>
              <md-list-item
                href="javascript:void(0)"
                @click="$router.push(localePath('/contact'))"
              >
                {{ $t('menu.contact') }}
              </md-list-item>

              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean"
                >
                  <div class="md-list-item-content">
                    <md-button
                      v-if="$auth && $auth.loggedIn"
                      class="md-success md-round"
                      @click="
                        $router.push(localePath('admin-dashboard-activity'))
                      "
                    >
                      <md-icon>account_circle</md-icon>
                      {{ $t('home.go_admin') }}
                    </md-button>
                    <md-button
                      v-else
                      class="md-success md-round"
                      @click="setSignupModal(true)"
                    >
                      <md-icon>account_box</md-icon> {{ $t('home.login') }}
                    </md-button>
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>-->
  <!--  <md-toolbar
    id="toolbar"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    }"
    :color-on-scroll="colorOnScroll"
    md-elevation="0"
    class="md-fixed"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">
          {{ brand }}
        </h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          :class="{ toggled: toggledClass }"
          class="md-just-icon md-simple md-toolbar-toggle"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
               Here you can add your items from the section-start of your toolbar 
            </mobile-menu>
            <md-list>
              <li v-if="showDownload" class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">menu</i>
                        <p>Menu</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li>
                          <a href="#/presentation">
                            <i class="material-icons">layers</i>
                            Presentation
                          </a>
                        </li>
                        <li>
                          <a href="#/">
                            <i class="material-icons">line_style</i>
                            All Components
                          </a>
                        </li>
                        <li>
                          <a :href="docs_link">
                            <i class="material-icons">content_paste</i>
                            Documentation
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
                            <li v-if="!showDownload" class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">apps</i>
                        <p>Components</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li>
                          <a href="#/presentation">
                            <i class="material-icons">layers</i>
                            Presentation
                          </a>
                        </li>
                        <li>
                          <a :href="docs_link">
                            <i class="material-icons">content_paste</i>
                            Documentation
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

                            <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">view_day</i>
                        <p>Sections</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li v-for="li in linksSections" :key="li.name">
                          <a
                            :href="'#/sections#' + li.name"
                            @click="
                              () => {
                                NavbarStore.showNavbar = false
                                toggledClass = false
                              }
                            "
                          >
                            <i class="material-icons">{{ li.icon }}</i>
                            {{
                              li.name.charAt(0).toUpperCase() + li.name.slice(1)
                            }}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

                            <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">view_carousel</i>
                        <p>Examples</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li v-for="li in linksExamples" :key="li.name">
                          <a :href="'#/' + li.href">
                            <i class="material-icons">{{ li.icon }}</i>
                            {{ li.name }}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean"
                >
                  <div class="md-list-item-content">
                    <md-button
                      v-if="$auth && $auth.loggedIn"
                      class="md-success md-round"
                      @click="
                        $router.push(localePath('admin-dashboard-activity'))
                      "
                    >
                      <md-icon>account_circle</md-icon>
                      {{ $t('home.go_admin') }}
                    </md-button>
                    <md-button
                      v-else
                      class="md-success md-round"
                      @click="setSignupModal(true)"
                    >
                      <md-icon>account_box</md-icon> {{ $t('home.login') }}
                    </md-button>
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>-->
</template>

<script>
import Vuex from 'vuex'

import MobileMenu from '@/components/public/layout/MobileMenu'

let resizeTimeout
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null
      actualResizeHandler()

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66)
  }
}
export default {
  components: {
    MobileMenu
  },
  props: {},
  data() {
    return {
      //      docs_link:
      //        'https://demos.creative-tim.com/vue-material-kit-pro/documentation/',
      navbarTransparent: true,
      colorOnScroll: 50,
      type: 'primary', //  'white', 'default', 'primary', 'danger', 'success', 'warning', 'info', 'dark'
      extraNavClasses: '',
      toggledClass: false,
      brand: 'GOYOGA Manager v2'
      //      linksSections: [
      //        { name: 'headers', icon: 'dns' },
      //        { name: 'features', icon: 'build' },
      //        { name: 'blogs', icon: 'list' },
      //        { name: 'teams', icon: 'people' },
      //        { name: 'projects', icon: 'assignment' },
      //        { name: 'pricing', icon: 'monetization_on' },
      //        { name: 'testimonials', icon: 'chat' },
      //        { name: 'contacts', icon: 'call' }
      //      ],
      //      linksExamples: [
      //        { name: 'About Us', href: 'about-us', icon: 'account_balance' },
      //        { name: 'Blog Post', href: 'blog-post', icon: 'art_track' },
      //        { name: 'Blog Posts', href: 'blog-posts', icon: 'view_quilt' },
      //        { name: 'Contact Us', href: 'contact-us', icon: 'location_on' },
      //        { name: 'Landing Page', href: 'landing-page', icon: 'view_day' },
      //        { name: 'Login Page', href: 'login-page', icon: 'fingerprint' },
      //        { name: 'Pricing Page', href: 'pricing-page', icon: 'attach_money' },
      //        {
      //          name: 'Shopping Cart',
      //          href: 'shopping-cart',
      //          icon: 'shopping_basket'
      //        },
      //        { name: 'Ecommerce Page', href: 'ecommerce-page', icon: 'store' },
      //        { name: 'Product Page', href: 'product-page', icon: 'shopping_cart' },
      //        { name: 'Profile Page', href: 'profile-page', icon: 'account_circle' },
      //        { name: 'Signup Page', href: 'signup-page', icon: 'person_add' },
      //        { name: 'Error Page', href: 'error-page', icon: 'error' }
      //      ]
    }
  },
  computed: {
    showDownload() {
      const excludedRoutes = ['index']
      return excludedRoutes.every((r) => r !== this.$route.name)
    }
  },
  mounted() {
    document.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    ...Vuex.mapMutations(['setSignupModal']),
    bodyClick() {
      const bodyClick = document.getElementById('bodyClick')

      if (bodyClick === null) {
        const body = document.querySelector('body')
        const elem = document.createElement('div')
        elem.setAttribute('id', 'bodyClick')
        body.appendChild(elem)

        const bodyClick = document.getElementById('bodyClick')
        bodyClick.addEventListener('click', this.toggleNavbarMobile)
      } else {
        bodyClick.remove()
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar
      this.toggledClass = !this.toggledClass
      this.bodyClick()
    },
    handleScroll() {
      const scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop
      const navbarColor = document.getElementById('toolbar')
      this.currentScrollValue = scrollValue
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`
        navbarColor.classList.remove('md-transparent')
      } else if (this.extraNavClasses) {
        this.extraNavClasses = ''
        navbarColor.classList.add('md-transparent')
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll)
    }
  }
}
</script>
