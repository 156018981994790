<template>
  <div>
    <div :class="{ show: show }" class="modal modal-bg">
      <modal
        v-if="show"
        modal-with-card
        type="modal-signup"
        card-classes="md-card-signup"
        @close="handleClose"
      >
        <template slot="header">
          <md-button
            class="md-simple md-close md-just-icon md-round modal-default-button"
            @click="handleClose"
          >
            <md-icon>clear</md-icon>
          </md-button>
          <div v-if="loginContext === 1">
            <h3 class="text-center md-title">
              {{ $t('register.labels.Title') }}
            </h3>

            <h4 class="text-center md-subheading icon icon-danger text-danger">
              <md-icon>warning</md-icon> {{ $t('register.labels.subTitle') }}
            </h4>
          </div>
          <template v-else-if="loginContext === 2">
            <h3 class="text-center md-display-2">
              {{ $t('login.labels.Title') }}
            </h3>
          </template>
          <template v-else-if="loginContext === 3">
            <h3 class="text-center md-display-2">
              {{ $t('lostpwd.labels.Title') }}
            </h3>
          </template>
          <template v-else-if="loginContext === 4">
            <h3 class="text-center md-display-2">
              {{ $t('lostpwd.labels.title_reset') }}
            </h3>
          </template>
        </template>

        <template slot="body">
          <div class="md-layout">
            <div
              slot="content-left"
              class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 ml-auto"
            >
              <div
                v-for="item in contentLeft"
                :key="item.title"
                class="info info-horizontal"
              >
                <div :class="`icon ${item.colorIcon}`">
                  <md-icon>{{ item.icon }}</md-icon>
                </div>
                <div class="description">
                  <h4 class="info-title">
                    {{ item.title }}
                  </h4>
                  <p class="description">
                    <span v-html="item.description" />
                  </p>
                </div>
              </div>
            </div>
            <div
              slot="content-right"
              class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto"
            >
              <!--              <div class="social-line text-center">
                <md-button class="md-just-icon md-round md-twitter">
                  <i class="fab fa-twitter" />
                </md-button>
                <md-button class="md-just-icon md-round md-dribbble">
                  <i class="fab fa-dribbble" />
                </md-button>
                <md-button class="md-just-icon md-round md-facebook">
                  <i class="fab fa-facebook-f" />
                </md-button>
                <h4 class="mt-3">
                  or be classical
                </h4>
              </div>-->
              <register-form
                v-if="loginContext === 1"
                @validate-register-form="validateRegisterForm"
              />

              <login-form
                v-if="loginContext === 2"
                @validate-login-form="validateLoginForm"
              />

              <lost-pwd-form
                v-if="loginContext === 3"
                @validate-lost-pwd-form="validateLostPwdForm"
              />

              <reset-pwd-form
                v-if="loginContext === 4"
                @validate-reset-pwd-form="validateResetPwdForm"
              />

              <div class="">
                <md-button
                  v-if="loginContext !== 1"
                  class="md-sm md-primary md-simple"
                  type="button"
                  @click="changeLoginContext(1)"
                >
                  <i class="fas fa-user-plus"></i>
                  {{ $t('lostpwd.labels.registerlink') }}
                </md-button>
                <md-button
                  v-if="loginContext !== 2"
                  class="md-sm md-primary md-simple"
                  type="button"
                  @click="changeLoginContext(2)"
                >
                  <i class="fas fa-sign-in-alt" />
                  {{ $t('lostpwd.labels.loginlink') }}
                </md-button>
                <md-button
                  v-if="loginContext !== 3"
                  class="md-sm md-primary md-simple"
                  type="button"
                  @click="changeLoginContext(3)"
                >
                  <i class="fas fa-unlock"></i>
                  {{ $t('register.labels.lostpwdlink') }}
                </md-button>
              </div>
            </div>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import RegisterForm from './login/RegisterForm'
import LoginForm from './login/LoginForm'
import LostPwdForm from './login/LostPwdForm'
import ResetPwdForm from './login/ResetPwdForm'
import { tools } from '@/plugins/tools.js'
import { Modal } from '@/components/creativeTim/kitPro/components'

export default {
  components: {
    Modal,
    RegisterForm,
    LoginForm,
    LostPwdForm,
    ResetPwdForm
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    loginContext: {
      // 1: Register, 2: Login, 3: LostPassword, 4: resetPassword
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      affiliates: false,
      tools,
      contentLeft: [
        {
          colorIcon: 'icon-danger',
          icon: 'timeline',
          title: this.$t('login.marketing_title'),
          description: this.$t('login.marketing_desc')
        },

        {
          colorIcon: 'icon-rose',
          icon: 'language',
          title: this.$t('login.website_title'),
          description: this.$t('login.website_desc')
        },

        {
          colorIcon: 'icon-warning',
          icon: 'euro',
          title: this.$t('login.manage_title'),
          description: this.$t('login.manage_desc')
        },

        {
          colorIcon: 'icon-info',
          icon: 'share',
          title: this.$t('login.share_title'),
          description: this.$t('login.share_desc')
        }
      ]
    }
  },
  computed: {
    ...mapState(['authBackRoute']),
    ...mapGetters(['loggedInUser'])
  },

  watch: {},

  created() {},

  mounted() {
    this.$nextTick(() => {
      if (this.$route.query && this.$route.query.t) {
        if (this.$route.fullPath.includes('reset-password')) {
          this.changeLoginContext(4)
        } else {
          this.changeLoginContext(1)
        }
      } else {
        this.changeLoginContext(2)
      }
    })
  },

  methods: {
    ...mapMutations(['setAuthBackRoute', 'setSignupModal']),
    ...mapActions(['register', 'requestPassword', 'resetPassword']),
    handleClose() {
      this.setSignupModal(false)
    },
    changeLoginContext(val) {
      this.$emit('changeLoginContext', val)
    },
    validateRegisterForm(model) {
      try {
        this.register(model)
          .then((res) => {
            if (res.status === 215) {
              const errorMsg =
                res.data.email && res.data.email[1]
                  ? this.$t('error.' + res.data.email[1])
                  : res.data.username && res.data.username[1]
                  ? this.$t('error.' + res.data.username[1])
                  : this.$t('error.undefined') +
                    ' : ' +
                    JSON.stringify(res.data)
              this.$toast
                .error(errorMsg, {
                  icon: 'warning'
                })
                .goAway(5000)
            } else {
              this.$toast
                .success(this.$t('common.save_success'), {
                  icon: 'check_circle'
                })
                .goAway(5000)
              const loginModel = {
                data: {
                  email: model.email,
                  password: model.plainPassword.first
                }
              }
              this.validateLoginForm(loginModel)
            }
          })
          .catch((e) => {
            this.$toast
              .error(e, {
                icon: 'warning'
              })
              .goAway(5000)
          })
      } catch (e) {
        if (e.response.data && e.response.data.message) {
          this.$toast
            .error(this.$t('register.errors.' + e.response.data.message), {
              icon: 'warning'
            })
            .goAway(5000)
        } else {
          this.$toast.error('Unknown error', { icon: 'warning' }).goAway(5000)
        }
      }
    },
    validateLoginForm(model) {
      try {
        this.$auth.loginWith('local', model).then((res) => {
          this.postLogin()
        })
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          this.$toast
            .error(this.$t('register.errors.' + e.response.data.message), {
              icon: 'warning'
            })
            .goAway(5000)
        } else {
          this.$toast
            .error('Unknown error: ' + JSON.stringify(e), { icon: 'warning' })
            .goAway(15000)
        }
      } finally {
      }
    },
    validateLostPwdForm(model) {
      try {
        this.requestPassword(model).then((res) => {
          this.$emit('hide-login-modal')
          this.$toast
            .success(this.$t('lostpwd.labels.done'), {
              icon: 'check_circle'
            })
            .goAway(10000)
        })
      } catch (e) {
        let error = ''
        if (e.response.data && e.response.data.message) {
          error = this.$t('lostpwd.errors.' + e.response.data.message)
          if (e.response.data.errors && e.response.data.errors.children) {
            Object.keys(e.response.data.errors.children).forEach((key) => {
              if (
                e.response.data.errors.children[key].errors &&
                e.response.data.errors.children[key].errors[0]
              ) {
                error +=
                  '<BR>' +
                  this.$t(
                    'lostpwd.errors.' +
                      e.response.data.errors.children[key].errors[0].replace(
                        '.',
                        ''
                      )
                  )
              }
            })
          }
        } else {
          error = 'Unknown error'
        }
        this.$toast.error(error, { icon: 'warning' }).goAway(10000)
      }
    },
    validateResetPwdForm(model) {
      try {
        this.resetPassword(model)
          .then((res) => {
            this.changeLoginContext(2)
            this.$toast
              .success(this.$t('lostpwd.labels.reset_pwd_done'), {
                icon: 'check_circle'
              })
              .goAway(10000)
          })
          .catch((e) => {
            this.$toast
              .error(this.$t('error.' + e), {
                icon: 'warning'
              })
              .goAway(15000)
          })
      } catch (e) {
        let error = ''
        if (e.response.data && e.response.data.message) {
          error = this.$t('lostpwd.errors.' + e.response.data.message)
          if (e.response.data.errors && e.response.data.errors.children) {
            Object.keys(e.response.data.errors.children).forEach((key) => {
              if (
                e.response.data.errors.children[key].errors &&
                e.response.data.errors.children[key].errors[0]
              ) {
                error +=
                  '<BR>' +
                  this.$t(
                    'lostpwd.errors.' +
                      e.response.data.errors.children[key].errors[0].replace(
                        '.',
                        ''
                      )
                  )
              }
            })
          }
        } else {
          error = 'Unknown error'
        }
        this.$toast.error(error, { icon: 'warning' }).goAway(10000)
      }
    },
    postLogin() {
      console.log('postLogin on manager-static')
      this.$emit('hide-login-modal')
      window.location.href = '/admin/dashboard/activity'
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-wrapper {
  display: block;
}
.info-horizontal ::v-deep .icon {
  margin-top: 0px;
}
.info ::v-deep .info-title {
  margin-top: 0px;
}
.md-theme-default h3.md-display-2 {
  color: #42b983;
  font-size: 1.5625rem;
}
</style>
