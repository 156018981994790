<template>
  <div class="md-layout">
    <md-field
      :class="[
        { 'md-error': errors.has(`${field.nameAttr}`) },
        {
          'md-valid': !errors.has(`${field.nameAttr}`) && field.touched
        }
      ]"
      class="md-layout-item no-padding-x"
    >
      <md-icon v-if="field.icon">{{ field.icon }}</md-icon>
      <label v-if="!isGmap" :for="field.nameAttr">{{ field.name }}</label>
      <template v-if="isGmap">
        <client-only>
          <input
            :id="field.nameAttr"
            :key="field.nameAttr"
            :ref="field.nameAttr + '-gmap'"
            v-model="field.value"
            v-validate.immediate="field.validate"
            :data-vv-name="field.nameAttr"
            :type="field.type"
            :name="field.nameAttr + '-gmap'"
            class="md-input"
            :message="errors.first(`${field.nameAttr}`)"
            :required="field.required"
            :disabled="field.disabled"
          />
        </client-only>
      </template>
      <template v-else-if="field.translations">
        <md-input
          :key="field.nameAttr"
          v-model="translatedValue"
          v-validate.immediate="field.validate"
          :data-vv-name="field.nameAttr"
          :type="field.type"
          :name="field.nameAttr"
          :message="errors.first(`${field.nameAttr}`)"
          :required="field.required"
          :disabled="field.disabled"
          @input="onChange"
        >
        </md-input>
      </template>
      <template v-else>
        <md-input
          ref="input"
          :key="field.nameAttr"
          v-model="field.value"
          v-validate.immediate="field.validate"
          :data-vv-name="field.nameAttr"
          :type="field.type"
          :name="field.nameAttr"
          :message="errors.first(`${field.nameAttr}`)"
          :required="field.required"
          :disabled="field.disabled"
          :step="field.step"
          @input="onChange"
        >
        </md-input>
      </template>
      <span class="md-helper-text">
        {{ errors.first(`${field.nameAttr}`) }}
      </span>
      <slide-y-down-transition>
        <span
          v-show="field.unlimited && (field.value === '0' || field.value === 0)"
          class="text-warning unlimited"
        >
          {{ $t('planning.capacity_unlimited') }}
        </span>
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon
          v-show="errors.has(`${field.nameAttr}`)"
          class="errror icon-right"
          >close</md-icon
        >
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon
          v-show="!errors.has(`${field.nameAttr}`) && field.touched"
          class="success icon-right"
          >done</md-icon
        >
      </slide-y-down-transition>
    </md-field>
    <drop-down v-if="field.translations" direction="up">
      <md-button
        slot="title"
        class="md-button md-white md-round md-block dropdown-toggle"
        data-toggle="dropdown"
      >
        <i v-if="lang === 'fr'" class="flag-icon flag-icon-fr"></i>
        <i v-else class="flag-icon flag-icon-gb"></i>
      </md-button>
      <ul class="dropdown-menu dropdown-menu-right">
        <li v-if="lang !== 'fr'" class="text-center" @click="switchLang('fr')">
          <i class="flag-icon flag-icon-fr"></i> Français
        </li>
        <li v-if="lang !== 'en'" class="text-center" @click="switchLang('en')">
          <i class="flag-icon flag-icon-gb"></i> English
        </li>
      </ul>
    </drop-down>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { SlideYDownTransition } from 'vue2-transitions'

// https://github.com/validatorjs/validator.js
import { Validator } from 'vee-validate'
import isMobilePhone from 'validator/lib/isMobilePhone'
import bic from 'bic'
import iban from 'iban'

import { tools } from '@/plugins/tools.js'

export default {
  components: {
    SlideYDownTransition
    //    VueGoogleAutocomplete
  },

  props: {
    field: {
      type: Object,
      default() {
        return {
          icon: '',
          help: false,
          name: '',
          nameAttr: '',
          value: [],
          type: 'text',
          validate: {},
          touched: false,
          gmapAddress: false,
          required: true,
          translations: false,
          disabled: false,
          isUrl: false,
          isIban: false,
          isBic: false
        }
      }
    },
    isGmap: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tools,
      bic,
      iban,
      gmapAutocomplete: false, // Google field
      lang: this.$i18n.locale,
      translatedValue: '',
      watchingValue: false,
      mountedComp: false
    }
  },

  computed: {
    ...Vuex.mapState(['isGmapLoaded']),
    formErrors() {
      const errors = this.$validator.errors
      return errors
    }
  },

  watch: {
    isGmapLoaded(val) {
      if (this.mountedComp && this.isGmap) {
        this.initGmapField()
        if (this.field.required) {
          this.$validator.validateAll()
        }
      }
    },
    mountedComp(val) {
      if (val && this.isGmapLoaded && this.isGmap) {
        this.initGmapField()
        if (this.field.required) {
          this.$validator.validateAll()
        }
      }
    },
    'field.value'(val, oldVal) {
      // Delete gmap address on input field empty
      if (this.gmapAutocomplete && val === '') {
        this.field.gmapAddress = false
      }
      // Init translated field on first load (data comes after mounted)
      if (this.field.translations) {
        this.translatedValue = this.getTranslatedValueLang(val)
      }
      if (this.field.isUrl && val) {
        val = val
          // eslint-disable-next-line
          .replace(/[&\/\\#,+()$~%.'":*?<>{}\[\]}{@^\\|ùµ!§;.€\séè$à=ç{}\[\^£%]/g, '')
          .toLowerCase()
        this.$refs.input.model = val
      }
      this.field.touched = true
    },
    // Init address text field
    'field.gmapAddress'(val, oldVal) {
      this.field.value = this.tools.addressToString(val)
    },
    // Update translations array value on input change
    translatedValue(val, oldVal) {
      let langExists = false
      for (const i in this.field.value) {
        if (this.field.value[i].lang === this.lang) {
          this.field.value[i].value = val
          langExists = true
          break
        }
      }
      if (!langExists) {
        this.field.value.push({
          lang: this.lang,
          value: val
        })
      }
    },
    formErrors: {
      handler(value) {
        return this.$emit('form-errors', value)
      },
      deep: true
    }
  },

  created() {},

  mounted() {
    // Init translated fields on tab change (if tabs)
    if (this.field.translations) {
      this.translatedValue = this.getTranslatedValueLang(this.lang)
    }

    // Add phoneNumber Validator
    const phoneNumber = {
      getMessage: (field) => this.$t('common.not_phone_format'),
      validate(value) {
        return new Promise((resolve) => {
          //          const phone = new PhoneNumber(value)
          //          resolve({ valid: phone.isValid() })
          resolve({ valid: isMobilePhone(value) })
        })
      }
    }
    Validator.extend('phoneNumber', phoneNumber)

    // Add gmap Validator
    if (this.isGmap) {
      const vm = this
      const gmap = {
        getMessage: (field) => this.$t('common.select_address'),
        validate(value) {
          return new Promise((resolve) => {
            resolve({ valid: vm.isGmapAddress(value) })
          })
        }
      }
      Validator.extend('gmap', gmap)
    }

    // Add iban Validator
    if (this.field.isIban) {
      const vm = this
      const iban = {
        getMessage: (field) => this.$t('common.not_iban_format'),
        validate(value) {
          return new Promise((resolve) => {
            resolve({ valid: vm.isIban(value) })
          })
        }
      }
      Validator.extend('iban', iban)
    }

    // Add bic Validator
    if (this.field.isBic) {
      const vm = this
      const bic = {
        getMessage: (field) => this.$t('common.not_bic_format'),
        validate(value) {
          return new Promise((resolve) => {
            resolve({ valid: vm.isBic(value) })
          })
        }
      }
      Validator.extend('bic', bic)
    }
    this.$nextTick(() => {
      this.mountedComp = true
    })
  },

  methods: {
    onChange(value) {
      if (this.field.isIban) {
        this.field.value = this.iban.printFormat(value, ' ')
      }
      this.$emit('change', value)
    },
    switchLang(lang) {
      this.lang = lang
      this.translatedValue = this.getTranslatedValueLang(lang)
    },
    getTranslatedValueLang(lang) {
      let translatedValue = ''
      for (const i in this.field.value) {
        if (this.field.value[i].lang === this.lang) {
          translatedValue = this.field.value[i].value
          break
        }
      }
      return translatedValue
    },
    initGmapField() {
      // Google doc: https://developers.google.com/maps/documentation/javascript/places-autocomplete
      // Simple doc: https://medium.com/@jpoechill/superbasic-google-autocomplete-with-nuxt-js-378a9262659a
      // Howto load google api in pages: https://vueschool.io/articles/vuejs-tutorials/how-to-load-third-party-scripts-in-nuxt-js/ (See in gmap related pages head)

      const options = {}
      // Set default geographical point
      // eslint-disable-next-line
      const defaultBounds = new google.maps.LatLngBounds(
        // eslint-disable-next-line
        new google.maps.LatLng(48.8588377, 2.2770205) // Search from Paris point
      )
      options.bounds = defaultBounds
      // Set search types
      options.types = ['address']

      // Get component
      const component = this.$refs[this.field.nameAttr + '-gmap']
      // Init gmap place autocomplete on it
      // eslint-disable-next-line
      this.gmapAutocomplete = new google.maps.places.Autocomplete(
        component,
        options
      )

      this.gmapAutocomplete.addListener('place_changed', this.onPlaceChanged)
    },
    isGmapAddress(value) {
      return !!this.field.gmapAddress
    },
    isIban(value) {
      return this.iban.isValid(value)
    },
    isBic(value) {
      return this.bic.isValid(value)
    },
    onPlaceChanged() {
      this.field.value = this.gmapAutocomplete.getPlace().formatted_address
      this.field.gmapAddress = this.getAddressData(
        this.gmapAutocomplete.getPlace()
      )
      this.$validator.validateAll()
    },
    getAddressData(addressData) {
      const address = {}
      /** zip code **/
      const zipCode = addressData.address_components.filter((code) => {
        return code.types.includes('postal_code')
      })
      if (zipCode && zipCode.length > 0) {
        address.zipCode = zipCode[0].long_name
      } else {
        address.zipCode = ''
      }
      /** city **/
      const city = addressData.address_components.filter((code) => {
        return code.types.includes('locality')
      })
      if (city && city.length > 0) {
        address.city = city[0].long_name
      } else {
        address.city = ''
      }
      /** country **/
      const country = addressData.address_components.filter((code) => {
        return code.types.includes('country')
      })
      if (country && country.length > 0) {
        address.country = country[0].long_name
      } else {
        address.country = ''
      }
      /** street number + route **/
      let streetNumber = addressData.address_components.filter((code) => {
        return code.types.includes('street_number')
      })
      const route = addressData.address_components.filter((code) => {
        return code.types.includes('route')
      })
      if (route && route.length > 0) {
        streetNumber =
          streetNumber && streetNumber.length > 0
            ? streetNumber[0].long_name
            : ''
        address.address = streetNumber + ' ' + route[0].long_name
        if (address.address === '')
          address.address = addressData.formatted_address
      } else {
        address.address = addressData.formatted_address
      }
      /** longitude latitude **/
      address.longitude = addressData.geometry.location.lng()
      address.latitude = addressData.geometry.location.lat()
      this.position = {
        lat: address.latitude,
        lng: address.longitude
      }
      return address
    }
  }
}
</script>

<style lang="scss" scoped>
.unlimited {
  position: absolute;
  bottom: -14px;
  font-size: 12px;
}
::v-deep input {
  margin-right: 30px;
}
::v-deep label {
  margin-left: 12px;
}
::v-deep .icon-right {
  margin-right: -20px;
}
</style>
